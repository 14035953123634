export const environment = {
  production: true,
  appVersion: '2.49.10-b65e9d7c',
  checkVersion: true,
  builtAppHash: 'b65e9d7c58f3a8f6adf8e7b6d6d5519803743874',
  centrifugoWebsocketUrl: 'wss://centri.zumti.com/connection/websocket',
  jitsiurl: 'meetx.turkpages.com',
  webSiteUrl: 'https://web.verirobot.com/',
  apiUrl: 'https://api.verirobot.com/',
  natsUrl: 'wss://centri.zumti.com',
  googleCientId: '572001134774-bveghvbh9jda001oge723ujj45jbrihq.apps.googleusercontent.com',
  livekitUrl:   'wss://live.verirobot.com',
  customerName: 'verirobotcom',
};